define("ember-collapsible-panel/components/cp-panels/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "26ytXUUE",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[27,\"hash\",null,[[\"panel\",\"name\"],[[27,\"component\",[\"cp-panel\"],[[\"group\"],[[22,0,[]]]]],[23,[\"name\"]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-collapsible-panel/components/cp-panels/template.hbs"
    }
  });

  _exports.default = _default;
});