define('ember-cp-validations/utils/assign', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = assign;


  const {
    get,
    set,
    isNone,
    defineProperty
  } = Ember;

  function assign(obj, path, value, useEmberObject = false, delimiter = '.') {
    let keyPath = path.split(delimiter);
    let lastKeyIndex = keyPath.length - 1;
    let currObj = obj;

    // Iterate over each key in the path (minus the last one which is the property to be assigned)
    for (let i = 0; i < lastKeyIndex; ++i) {
      let key = keyPath[i];

      // Create a new object if it doesnt exist
      if (isNone(get(currObj, key))) {
        set(currObj, key, useEmberObject ? Ember.Object.create() : {});
      }
      currObj = get(currObj, key);
    }

    if (value instanceof Ember.ComputedProperty) {
      defineProperty(currObj, keyPath[lastKeyIndex], value);
    } else {
      set(currObj, keyPath[lastKeyIndex], value);
    }
  }
});