define("ember-cli-lightbox/templates/components/light-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zMgeZ6To",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"inlineImage\"]]],null,{\"statements\":[[7,\"img\"],[12,\"src\",[28,[[27,\"unbound\",[[23,[\"href\"]]],null]]]],[12,\"title\",[28,[[27,\"unbound\",[[23,[\"data-title\"]]],null]]]],[12,\"class\",[28,[[27,\"unbound\",[[23,[\"data-class\"]]],null]]]],[9],[10]],\"parameters\":[]},null],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cli-lightbox/templates/components/light-box.hbs"
    }
  });

  _exports.default = _default;
});