define("ember-basic-dropdown/components/basic-dropdown", ["exports", "ember-basic-dropdown/templates/components/basic-dropdown", "ember-basic-dropdown/utils/computed-fallback-if-undefined", "ember-basic-dropdown/utils/calculate-position", "require"], function (_exports, _basicDropdown, _computedFallbackIfUndefined, _calculatePosition, _require) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const assign = Object.assign || function EmberAssign(original) {
    for (let i = 0; i < (arguments.length <= 1 ? 0 : arguments.length - 1); i++) {
      let arg = i + 1 < 1 || arguments.length <= i + 1 ? undefined : arguments[i + 1];

      if (!arg) {
        continue;
      }

      let updates = Object.keys(arg);

      for (let i = 0; i < updates.length; i++) {
        let prop = updates[i];
        original[prop] = arg[prop];
      }
    }

    return original;
  };

  const ignoredStyleAttrs = ['top', 'left', 'right', 'width', 'height'];

  var _default = Ember.Component.extend({
    layout: _basicDropdown.default,
    tagName: '',
    renderInPlace: (0, _computedFallbackIfUndefined.default)(false),
    verticalPosition: (0, _computedFallbackIfUndefined.default)('auto'),
    // above | below
    horizontalPosition: (0, _computedFallbackIfUndefined.default)('auto'),
    // auto-right | right | center | left
    rootEventType: (0, _computedFallbackIfUndefined.default)('mousedown'),
    // TODO: Change default event type to "click" in 2.0
    matchTriggerWidth: (0, _computedFallbackIfUndefined.default)(false),
    triggerComponent: (0, _computedFallbackIfUndefined.default)('basic-dropdown/trigger'),
    contentComponent: (0, _computedFallbackIfUndefined.default)('basic-dropdown/content'),
    calculatePosition: (0, _computedFallbackIfUndefined.default)(_calculatePosition.default),
    classNames: ['ember-basic-dropdown'],
    top: null,
    left: null,
    right: null,
    width: null,
    height: null,
    otherStyles: {},

    // eslint-disable-line
    // Lifecycle hooks
    init() {
      if (this.get('renderInPlace') && this.get('tagName') === '') {
        Ember.set(this, 'tagName', 'div');
      }

      this._super(...arguments);

      Ember.set(this, 'publicAPI', {});
      Ember.set(this, 'otherStyles', {});
      let publicAPI = this.updateState({
        uniqueId: Ember.guidFor(this),
        isOpen: this.get('initiallyOpened') || false,
        disabled: this.get('disabled') || false,
        actions: {
          open: this.open.bind(this),
          close: this.close.bind(this),
          toggle: this.toggle.bind(this),
          reposition: this.reposition.bind(this)
        }
      });
      this.dropdownId = this.dropdownId || `ember-basic-dropdown-content-${publicAPI.uniqueId}`;
      let onInit = this.get('onInit');

      if (onInit) {
        onInit(publicAPI);
      }
    },

    didReceiveAttrs() {
      this._super(...arguments);

      let oldDisabled = !!this._oldDisabled;
      let newDisabled = !!this.get('disabled');
      this._oldDisabled = newDisabled;

      if (newDisabled && !oldDisabled) {
        Ember.run.join(this, this.disable);
      } else if (!newDisabled && oldDisabled) {
        Ember.run.join(this, this.enable);
      }
    },

    willDestroy() {
      this._super(...arguments);

      let registerAPI = this.get('registerAPI');

      if (registerAPI) {
        registerAPI(null);
      }
    },

    // CPs
    destination: Ember.computed({
      get() {
        return this._getDestinationId();
      },

      set(_, v) {
        return v === undefined ? this._getDestinationId() : v;
      }

    }),
    // Actions
    actions: {
      handleFocus(e) {
        let onFocus = this.get('onFocus');

        if (onFocus) {
          onFocus(this.get('publicAPI'), e);
        }
      }

    },

    // Methods
    open(e) {
      if (this.get('isDestroyed')) {
        return;
      }

      let publicAPI = this.get('publicAPI');

      if (publicAPI.disabled || publicAPI.isOpen) {
        return;
      }

      let onOpen = this.get('onOpen');

      if (onOpen && onOpen(publicAPI, e) === false) {
        return;
      }

      this.updateState({
        isOpen: true
      });
    },

    close(e, skipFocus) {
      if (this.get('isDestroyed')) {
        return;
      }

      let publicAPI = this.get('publicAPI');

      if (publicAPI.disabled || !publicAPI.isOpen) {
        return;
      }

      let onClose = this.get('onClose');

      if (onClose && onClose(publicAPI, e) === false) {
        return;
      }

      if (this.get('isDestroyed')) {
        return;
      }

      this.setProperties({
        hPosition: null,
        vPosition: null,
        top: null,
        left: null,
        right: null,
        width: null,
        height: null,
        previousVerticalPosition: null,
        previousHorizontalPosition: null
      });
      this.updateState({
        isOpen: false
      });

      if (skipFocus) {
        return;
      }

      let trigger = document.querySelector(`[data-ebd-id=${publicAPI.uniqueId}-trigger]`);

      if (trigger && trigger.tabIndex > -1) {
        trigger.focus();
      }
    },

    toggle(e) {
      if (this.get('publicAPI.isOpen')) {
        this.close(e);
      } else {
        this.open(e);
      }
    },

    reposition() {
      let publicAPI = this.get('publicAPI');

      if (!publicAPI.isOpen) {
        return;
      }

      let dropdownElement = document.getElementById(this.dropdownId);
      let triggerElement = document.querySelector(`[data-ebd-id=${publicAPI.uniqueId}-trigger]`);

      if (!dropdownElement || !triggerElement) {
        return;
      }

      Ember.set(this, 'destinationElement', this.destinationElement || document.getElementById(this.get('destination')));
      let options = this.getProperties('horizontalPosition', 'verticalPosition', 'matchTriggerWidth', 'previousHorizontalPosition', 'previousVerticalPosition', 'renderInPlace');
      options.dropdown = this;
      let positionData = this.get('calculatePosition')(triggerElement, dropdownElement, this.destinationElement, options);
      return this.applyReposition(triggerElement, dropdownElement, positionData);
    },

    applyReposition(trigger, dropdown, positions) {
      let changes = {
        hPosition: positions.horizontalPosition,
        vPosition: positions.verticalPosition,
        otherStyles: this.get('otherStyles')
      };

      if (positions.style) {
        if (positions.style.top !== undefined) {
          changes.top = `${positions.style.top}px`;
        } // The component can be aligned from the right or from the left, but not from both.


        if (positions.style.left !== undefined) {
          changes.left = `${positions.style.left}px`;
          changes.right = null; // Since we set the first run manually we may need to unset the `right` property.

          if (positions.style.right !== undefined) {
            positions.style.right = undefined;
          }
        } else if (positions.style.right !== undefined) {
          changes.right = `${positions.style.right}px`;
          changes.left = null;
        }

        if (positions.style.width !== undefined) {
          changes.width = `${positions.style.width}px`;
        }

        if (positions.style.height !== undefined) {
          changes.height = `${positions.style.height}px`;
        }

        Object.keys(positions.style).forEach(attr => {
          if (ignoredStyleAttrs.indexOf(attr) === -1) {
            if (changes[attr] !== positions.style[attr]) {
              changes.otherStyles[attr] = positions.style[attr];
            }
          }
        });

        if (this.get('top') === null) {
          // Bypass Ember on the first reposition only to avoid flickering.
          let cssRules = [];

          for (let prop in positions.style) {
            if (positions.style[prop] !== undefined) {
              if (typeof positions.style[prop] === 'number') {
                cssRules.push(`${prop}: ${positions.style[prop]}px`);
              } else {
                cssRules.push(`${prop}: ${positions.style[prop]}`);
              }
            }
          }

          dropdown.setAttribute('style', cssRules.join(';'));
        }
      }

      changes.previousHorizontalPosition = positions.horizontalPosition;
      changes.previousVerticalPosition = positions.verticalPosition;
      this.setProperties(changes);
      return changes;
    },

    disable() {
      let publicAPI = this.get('publicAPI');

      if (publicAPI.isOpen) {
        publicAPI.actions.close();
      }

      this.updateState({
        disabled: true
      });
    },

    enable() {
      this.updateState({
        disabled: false
      });
    },

    updateState(changes) {
      let newState = Ember.set(this, 'publicAPI', assign({}, this.get('publicAPI'), changes));
      let registerAPI = this.get('registerAPI');

      if (registerAPI) {
        registerAPI(newState);
      }

      return newState;
    },

    _getDestinationId() {
      let config = Ember.getOwner(this).resolveRegistration('config:environment');

      if (config.environment === 'test' && typeof FastBoot === 'undefined') {
        if (true
        /* DEBUG */
        ) {
          let id, rootView;

          if (_require.default.has('@ember/test-helpers/dom/get-root-element')) {
            try {
              id = (0, _require.default)('@ember/test-helpers/dom/get-root-element').default().id;
            } catch (ex) {// no op
            }
          }

          if (!id) {
            rootView = document.querySelector('#ember-testing > .ember-view');
            id = rootView ? rootView.id : undefined;
          }

          return id;
        }
      }

      return config['ember-basic-dropdown'] && config['ember-basic-dropdown'].destination || 'ember-basic-dropdown-wormhole';
    }

  });

  _exports.default = _default;
});