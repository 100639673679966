define("ember-burger-menu/templates/components/burger-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jwdTV+Z2",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[27,\"hash\",null,[[\"outlet\",\"menu\",\"state\"],[[27,\"component\",[\"bm-outlet\"],[[\"containerId\",\"state\"],[[23,[\"elementId\"]],[23,[\"state\"]]]]],[27,\"component\",[\"bm-menu\"],[[\"containerId\",\"state\"],[[23,[\"elementId\"]],[23,[\"state\"]]]]],[23,[\"state\"]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-burger-menu/templates/components/burger-menu.hbs"
    }
  });

  _exports.default = _default;
});