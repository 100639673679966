define("ember-radio-button/templates/components/radio-button-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3/rWZEpA",
    "block": "{\"symbols\":[],\"statements\":[],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-radio-button/templates/components/radio-button-input.hbs"
    }
  });

  _exports.default = _default;
});