define('ember-slide-push-menu/components/esp-menu', ['exports', 'ember-slide-push-menu/templates/components/esp-menu'], function (exports, _espMenu) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const BODY_PUSH_CLASS = {
    'left': 'cbp-spmenu-push-toright',
    'right': 'cbp-spmenu-push-toleft',
    'top': null,
    'bottom': null
  };

  exports.default = Ember.Component.extend({
    layout: _espMenu.default,
    tagName: 'nav',
    classNames: ['cbp-spmenu'],
    classNameBindings: ['horizontal:cbp-spmenu-horizontal', 'vertical:cbp-spmenu-vertical', 'positionClass', 'open:cbp-spmenu-open', 'customClasses'],

    /**
       Boolean flag to set the menu to horizontal or vertical style
        @public
       @property horizontal
       @type { Boolean }
       @default false
    */

    horizontal: false,
    /**
       Boolean computed prperty based on the negative value of horizontal
        @public
       @property vertical
       @type { Computed }
    */

    vertical: Ember.computed.not('horizontal'),
    /**
       Boolean flag to render the menu as either slide or push menu
        - pushMenu = true => Renders Push Menu
        - pushMenu = false => Renders Slide Menu (Default behaviour)
        @public
       @property pushMenu
       @type { Boolean }
       @default false
    */

    pushMenu: false,
    positionClass: Ember.computed('position', function () {
      return `cbp-spmenu-${this.get('position')}`;
    }),

    bodyClass: Ember.computed('position', function () {
      return BODY_PUSH_CLASS[this.get('position')];
    }),

    didInsertElement() {
      this._super(...arguments);
      Ember.$('body').addClass('cbp-spmenu-push');
    },

    didUpdateAttrs() {
      this._super(...arguments);
      this.updateBodyClass();
    },

    updateBodyClass() {
      let _bodyClass = this.get('bodyClass');
      // Clean up
      Ember.$('body').removeClass('cbp-spmenu-push-toleft');
      Ember.$('body').removeClass('cbp-spmenu-push-toright');
      if (this.get('pushMenu')) {
        Ember.$('body').addClass(_bodyClass);
      }
    },

    willDestroyElement() {
      this._super(...arguments);
      Ember.$('body').removeClass('cbp-spmenu-push');
    }

  });
});