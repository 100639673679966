define('ember-radio-button/components/radio-button-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'input',
    type: 'radio',

    // value - required
    // groupValue - required

    // autofocus - boolean
    // disabled - optional
    // name - optional
    // required - optional
    // radioClass - string
    // radioId - string
    // tabindex - number
    // ariaLabelledby - string
    // ariaDescribedby - string

    defaultLayout: null, // ie8 support

    attributeBindings: ['autofocus', 'checked', 'disabled', 'name', 'required', 'tabindex', 'type', 'value', 'ariaLabelledby:aria-labelledby', 'ariaDescribedby:aria-describedby'],

    checked: Ember.computed('groupValue', 'value', function () {
      return Ember.isEqual(this.get('groupValue'), this.get('value'));
    }).readOnly(),

    sendChangedAction() {
      this.sendAction('changed', this.get('value'));
    },

    change() {
      let value = this.get('value');
      let groupValue = this.get('groupValue');

      if (groupValue !== value) {
        this.set('groupValue', value); // violates DDAU
        Ember.run.once(this, 'sendChangedAction');
      }
    }
  });
});