define("ember-multiselect-checkboxes/templates/components/multiselect-checkboxes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YjCN+oy6",
    "block": "{\"symbols\":[\"checkbox\",\"index\",\"&default\"],\"statements\":[[4,\"each\",[[23,[\"checkboxes\"]]],null,{\"statements\":[[4,\"if\",[[24,3]],null,{\"statements\":[[0,\"    \"],[14,3,[[22,1,[\"option\"]],[22,1,[\"isSelected\"]],[22,2,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"li\"],[9],[0,\"\\n      \"],[7,\"label\"],[9],[0,\"\\n        \"],[1,[27,\"input\",null,[[\"type\",\"checked\",\"disabled\"],[\"checkbox\",[22,1,[\"isSelected\"]],[23,[\"disabled\"]]]]],false],[0,\"\\n        \"],[1,[22,1,[\"label\"]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-multiselect-checkboxes/templates/components/multiselect-checkboxes.hbs"
    }
  });

  _exports.default = _default;
});