define('ember-validators/index', ['exports', 'ember-require-module'], function (exports, _emberRequireModule) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.validate = validate;


  const {
    assert,
    isPresent
  } = Ember;

  function validate(type, ...args) {
    let validator = (0, _emberRequireModule.default)(`ember-validators/${type}`);

    assert(`Validator not found of type: ${type}.`, isPresent(validator));

    return validator(...args);
  }
});