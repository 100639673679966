define('ember-flatpickr/components/ember-flatpickr', ['exports', 'ember-diff-attrs'], function (exports, _emberDiffAttrs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'input',
    type: 'text',
    attributeBindings: ['placeholder', 'type'],
    date: null,
    flatpickrRef: null,

    setupComponent: Ember.on('init', function () {
      // Require that users pass a date
      (true && !(this.get('date') !== undefined) && Ember.assert('{{ember-flatpickr}} requires a `date` to be passed as the value for flatpickr.', this.get('date') !== undefined));

      // Require that users pass an onChange

      (true && !(this.get('onChange') !== undefined) && Ember.assert('{{ember-flatpickr}} requires an `onChange` action or null for no action.', this.get('onChange') !== undefined));

      // Pass all values and setup flatpickr

      Ember.run.scheduleOnce('afterRender', this, function () {
        const fastboot = Ember.getOwner(this).lookup('service:fastboot');
        if (fastboot && fastboot.isFastBoot) {
          return;
        }
        const options = this.getProperties(Object.keys(this.attrs));

        // Add defaultDate, change and close handlers
        Ember.assign(options, {
          defaultDate: this.get('date'),
          onChange: this._onChange.bind(this),
          onClose: this._onClose.bind(this),
          onOpen: this._onOpen.bind(this),
          onReady: this._onReady.bind(this)
        });

        const flatpickrRef = flatpickr(this.element, options);

        if (this.get('appendDataInput')) {
          this.element.setAttribute('data-input', '');
        }

        this._setDisabled(this.get('disabled'));

        this.set('flatpickrRef', flatpickrRef);
      });
    }),

    didReceiveAttrs: (0, _emberDiffAttrs.default)('date', 'disabled', 'locale', 'maxDate', 'minDate', function (changedAttrs, ...args) {
      this._super(...args);

      this._attributeHasChanged(changedAttrs, 'date', newDate => {
        if (typeof newDate !== 'undefined') {
          this.element._flatpickr.setDate(newDate);
        }
      });

      this._attributeHasChanged(changedAttrs, 'disabled', newDisabled => {
        if (typeof newDisabled !== 'undefined') {
          this._setDisabled(newDisabled);
        }
      });

      this._attributeHasChanged(changedAttrs, 'locale', () => {
        this.element._flatpickr.destroy();
        this.setupComponent();
      });

      this._attributeHasChanged(changedAttrs, 'maxDate', newMaxDate => {
        this.element._flatpickr.set('maxDate', newMaxDate);
      });

      this._attributeHasChanged(changedAttrs, 'minDate', newMinDate => {
        this.element._flatpickr.set('minDate', newMinDate);
      });
    }),

    willDestroyElement() {
      this.element._flatpickr.destroy();
    },

    /**
     * Check if the attr has changed, and if so call the callback with the new value
     * @param {object} changedAttrs The object with keys denoting attrs that have changed
     * @param {string} attr The string of which attr to check for changes
     * @param {function} callback A function to call with the newAttr value
     * @private
     */
    _attributeHasChanged(changedAttrs, attr, callback) {
      if (changedAttrs && changedAttrs[attr]) {
        const [oldAttr, newAttr] = changedAttrs[attr];
        if (oldAttr !== newAttr) {
          callback(newAttr);
        }
      }
    },

    /**
     * When the date is changed, update the value and send 'onChange' action
     * @param selectedDates The array of selected dates
     * @param dateStr The string representation of the date, formatted by dateFormat
     * @param instance The flatpickr instance
     * @private
     */
    _onChange(selectedDates, dateStr, instance) {
      this.sendAction('onChange', selectedDates, dateStr, instance);
    },

    /**
     * When the flatpickr is closed, fire the 'onClose' action
     * @param selectedDates The array of selected dates
     * @param dateStr The string representation of the date, formatted by dateFormat
     * @param instance The flatpickr instance
     * @private
     */
    _onClose(selectedDates, dateStr, instance) {
      this.sendAction('onClose', selectedDates, dateStr, instance);
    },

    /**
     * When the flatpickr is opened, fire the 'onOpen' action
     * @param selectedDates The array of selected dates
     * @param dateStr The string representation of the date, formatted by dateFormat
     * @param instance The flatpickr instance
     * @private
     */
    _onOpen(selectedDates, dateStr, instance) {
      this.sendAction('onOpen', selectedDates, dateStr, instance);
    },

    /**
     * When the flatpickr is ready, fire the 'onReady' action
     * @param selectedDates The array of selected dates
     * @param dateStr The string representation of the date, formatted by dateFormat
     * @param instance The flatpickr instance
     * @private
     */
    _onReady(selectedDates, dateStr, instance) {
      this.sendAction('onReady', selectedDates, dateStr, instance);
    },

    /**
     * Set disabled for the correct input, handling altInput weirdness
     * @param {boolean} disabled Disabled or not
     * @private
     */
    _setDisabled(disabled) {
      if (this.get('altInput')) {
        this.element.nextSibling.disabled = disabled;
      } else {
        this.element.disabled = disabled;
      }
    }
  });
});