define('ember-side-menu/components/content-backdrop', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    sideMenu: Ember.inject.service(),

    progress: Ember.computed.oneWay('sideMenu.progress'),

    attributeBindings: ['style'],
    classNames: ['content-backdrop'],

    style: Ember.computed('progress', function () {
      const progress = Ember.get(this, 'progress');
      const opacity = progress / 100;
      const visibility = progress === 0 ? 'hidden' : 'visible';
      let transition = 'none';

      if (progress === 100) {
        transition = 'opacity 0.2s ease-out';
      } else if (progress === 0) {
        transition = 'visibility 0s linear 0.2s, opacity 0.2s ease-out';
      }

      return Ember.String.htmlSafe(`opacity: ${opacity}; visibility: ${visibility}; transition: ${transition}`);
    }),

    click() {
      Ember.get(this, 'sideMenu').close();
    },

    touchEnd() {
      Ember.get(this, 'sideMenu').close();
    }
  });
});