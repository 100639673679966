define('ember-element-resize-detector/components/resize-detector', ['exports', 'ember-element-resize-detector/templates/components/resize-detector'], function (exports, _resizeDetector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const {
    inject: { service },
    run: { scheduleOnce, bind }
  } = Ember;

  exports.default = Ember.Component.extend({
    layout: _resizeDetector.default,
    tagName: '',
    resizeDetector: service(),

    didInsertElement() {
      this._super(...arguments);

      scheduleOnce('afterRender', this, this.setup);
    },

    setup() {
      this.callback = bind(this, this.onResize);
      this.get('resizeDetector').setup(this.get('selector'), this.callback);
    },

    teardown() {
      this.get('resizeDetector').teardown(this.get('selector'), this.callback);
    },

    onResize(element) {
      if (this.get('isDestroyed') || this.get('isDestroying')) {
        return;
      }
      let $el = Ember.$(element);
      this.sendAction('on-resize', {
        width: $el.width(),
        height: $el.height()
      }, element);
    },

    willDestroyElement() {
      this.teardown();

      this._super(...arguments);
    }
  }).reopenClass({
    positionalParams: ['selector']
  });
});