define('ember-burger-menu/components/burger-menu', ['exports', 'ember-burger-menu/templates/components/burger-menu', 'ember-burger-menu/computed/style-for', 'ember-burger-menu/mixins/swipe-support', 'ember-burger-menu/-private/state', 'ember-lifeline/mixins/dom', 'ember-burger-menu/utils/is-fastboot', 'ember-burger-menu/utils/element-closest', 'ember-jquery-legacy'], function (exports, _burgerMenu, _styleFor, _swipeSupport, _state, _dom, _isFastboot, _elementClosest, _emberJqueryLegacy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_dom.default, _swipeSupport.default, {
    layout: _burgerMenu.default,
    classNames: ['ember-burger-menu'],
    classNameBindings: ['open:is-open', 'translucentOverlay', 'animationClass', 'itemAnimationClass', 'position'],
    attributeBindings: ['style'],

    translucentOverlay: true,
    dismissOnClick: true,
    dismissOnEsc: true,
    gesturesEnabled: true,

    state: Ember.computed(() => _state.default.create()).readOnly(),

    open: Ember.computed.alias('state.open'),
    locked: Ember.computed.alias('state.locked'),
    position: Ember.computed.alias('state.position'),
    width: Ember.computed.alias('state.width'),
    animation: Ember.computed.alias('state.animation'),
    itemAnimation: Ember.computed.alias('state.itemAnimation'),
    customAnimation: Ember.computed.alias('state.customAnimation'),

    style: (0, _styleFor.default)('container').readOnly(),

    animationClass: Ember.computed('state.styles.animation', function () {
      let animation = this.get('state.styles.animation');
      return animation ? `bm--${animation}` : '';
    }).readOnly(),

    itemAnimationClass: Ember.computed('state.styles.itemAnimation', function () {
      let itemAnimation = this.get('state.styles.itemAnimation');
      return itemAnimation ? `bm-item--${itemAnimation}` : '';
    }).readOnly(),

    willDestroyElement() {
      this._super(...arguments);
      Ember.run.cancel(this._setupEventsTimer);
    },

    setupEvents: Ember.on('didReceiveAttrs', Ember.observer('open', 'locked', function () {
      if ((0, _isFastboot.default)()) {
        return;
      }

      let methodName = this.get('open') && !this.get('locked') ? '_setupEvents' : '_teardownEvents';
      this._setupEventsTimer = Ember.run.scheduleOnce('afterRender', this, methodName);
    })),

    _setupEvents() {
      if (this.get('dismissOnClick')) {
        this.addEventListener(document.body, `click`, this.onClick);
        this.addEventListener(document.body, `touchstart`, this.onClick);
      }

      if (this.get('dismissOnEsc')) {
        this.addEventListener(window, `keyup`, this.onKeyup);
      }
    },

    _teardownEvents() {
      this.removeEventListener(document.body, `click`, this.onClick);
      this.removeEventListener(document.body, `touchstart`, this.onClick);
      this.removeEventListener(window, `keyup`, this.onKeyup);
    },

    onClick(e) {
      let nativeEvent = (0, _emberJqueryLegacy.normalizeEvent)(e);
      let elementId = this.get('elementId');
      // Close the menu if clicked outside of it
      if (!(0, _elementClosest.default)(nativeEvent.target, `#${elementId} .bm-menu`)) {
        this.get('state.actions').close();
      }
    },

    onKeyup(e) {
      let nativeEvent = (0, _emberJqueryLegacy.normalizeEvent)(e);
      if (nativeEvent.keyCode === 27) {
        this.get('state.actions').close();
      }
    },

    onSwipe(direction, target) {
      let position = this.get('position');
      let open = this.get('open');
      let gesturesEnabled = this.get('gesturesEnabled');
      let isMenuSwipe = (0, _elementClosest.default)(target, '.bm-menu');

      if (!gesturesEnabled) {
        return;
      }

      if (open && isMenuSwipe && position === direction) {
        this.get('state.actions').close();
      } else if (!open && position !== direction) {
        this.get('state.actions').open();
      }
    }
  });
});