define('ember-side-menu/initializers/side-menu', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() /* application */{
    // application.inject("route", "foo", "service:foo");
  }

  exports.default = {
    name: 'side-menu',
    initialize
  };
});