define("ember-print-this/templates/components/print-this", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aIU0xrru",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[12,\"class\",[28,[[21,\"defaultPrintClass\"]]]],[9],[0,\"\\n  \"],[14,1,[[27,\"action\",[[22,0,[]],\"doPrint\"],null]]],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-print-this/templates/components/print-this.hbs"
    }
  });

  _exports.default = _default;
});