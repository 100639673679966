enifed("@ember/-internals/views/lib/views/states/in_dom", ["exports", "@ember/polyfills", "@ember/-internals/metal", "@ember/error", "@ember/-internals/views/lib/views/states/has_element"], function (_exports, _polyfills, _metal, _error, _has_element) {
  "use strict";

  _exports.default = void 0;
  var inDOM = (0, _polyfills.assign)({}, _has_element.default, {
    enter: function (view) {
      // Register the view for event handling. This hash is used by
      // Ember.EventDispatcher to dispatch incoming events.
      view.renderer.register(view);

      if (true
      /* DEBUG */
      ) {
          (0, _metal.addObserver)(view, 'elementId', function () {
            throw new _error.default("Changing a view's elementId after creation is not allowed");
          });
        }
    },
    exit: function (view) {
      view.renderer.unregister(view);
    }
  });

  var _default = Object.freeze(inDOM);

  _exports.default = _default;
});