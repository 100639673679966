define('ember-burger-menu/animations/base', ['exports', 'ember-burger-menu/utils/css-stringify'], function (exports, _cssStringify) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Animation = Ember.Object.extend({
    animation: null,
    itemAnimation: null,

    container() /* open, width, right */{
      return {};
    },

    outlet() /* open, width, right */{
      return {};
    },

    menu() /* open, width, right */{
      return {};
    },

    menuItem() /* open, width, right, index */{
      return {};
    },

    generateCSSFor(type, { open, width, position, index }) {
      let result;

      (true && !(typeof width === 'number') && Ember.assert('Width must be a number.', typeof width === 'number'));
      (true && !(position === 'left' || position === 'right') && Ember.assert('Position must be either \'left\' or \'right\'.', position === 'left' || position === 'right'));


      if (type === 'menuItem' && index === -1) {
        /*
          If the index is -1 that means the specific menu item hasnt been
          rendered yet or it isn't found.
         */
        result = {};
      } else {
        result = this[type](open, width, position === 'right', index);
      }

      if (type === 'menu') {
        Ember.assign(result, { width: `${width}px` });
      }

      return (0, _cssStringify.default)(result);
    }
  });

  Animation.reopenClass({
    __isAnimation__: true
  });

  exports.default = Animation;
});