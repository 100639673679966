define("ember-burger-menu/templates/components/bm-outlet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TGnYrj9U",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[12,\"id\",[27,\"concat\",[[23,[\"containerId\"]],\"-outlet-menu\"],null]],[11,\"class\",\"bm-menu-container\"],[9],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"bm-content\"],[9],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-burger-menu/templates/components/bm-outlet.hbs"
    }
  });

  _exports.default = _default;
});