define('ember-inputmask/components/number-input', ['exports', 'ember-inputmask/components/input-mask'], function (exports, _inputMask) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _inputMask.default.extend({
    mask: 'integer',

    // Default options
    decimal: false,
    group: false,
    separator: ',',
    radix: '.',
    groupSize: '3',
    digitsOptional: true,
    min: undefined,
    max: undefined,
    prefix: '',
    suffix: '',
    unmaskAsNumber: false,

    oldComponent: '{{number-input}}',
    newComponent: '{{one-way-number-mask}}',

    updateMask: function () {
      this.setProperties({
        'options.autoGroup': this.get('group'),
        'options.groupSeparator': this.get('separator'),
        'options.radixPoint': this.get('radix'),
        'options.groupSize': this.get('groupSize'),
        'options.digitsOptional': this.get('digitsOptional'),
        'options.min': this.get('min'),
        'options.max': this.get('max'),
        'options.prefix': this.get('prefix'),
        'options.suffix': this.get('suffix'),
        'options.unmaskAsNumber': this.get('unmaskAsNumber')
      });

      if (this.get('decimal') === true) {
        this.set('mask', 'decimal');
        this.set('options.digits', 2);
      } else if (this.get('decimal')) {
        this.set('mask', 'decimal');
        this.set('options.digits', this.get('decimal'));
      }

      this._super();
    },

    _maskShouldChange: Ember.observer('mask', 'group', 'decimal', 'separator', 'radix', 'groupSize', 'digitsOptional', 'min', 'max', 'prefix', 'suffix', 'unmaskAsNumber', function () {
      Ember.run.once(this, 'updateMask');
    })
  });
});