define("ember-lifeline/index", ["exports", "ember-lifeline/run-task", "ember-lifeline/poll-task", "ember-lifeline/debounce-task", "ember-lifeline/dom-event-listeners", "ember-lifeline/utils/disposable", "ember-lifeline/mixins/run", "ember-lifeline/mixins/dom", "ember-lifeline/mixins/disposable"], function (_exports, _runTask, _pollTask, _debounceTask, _domEventListeners, _disposable, _run, _dom, _disposable2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "ContextBoundEventListenersMixin", {
    enumerable: true,
    get: function () {
      return _dom.default;
    }
  });
  Object.defineProperty(_exports, "ContextBoundTasksMixin", {
    enumerable: true,
    get: function () {
      return _run.default;
    }
  });
  Object.defineProperty(_exports, "DisposableMixin", {
    enumerable: true,
    get: function () {
      return _disposable2.default;
    }
  });
  Object.defineProperty(_exports, "_setRegisteredDisposables", {
    enumerable: true,
    get: function () {
      return _disposable._setRegisteredDisposables;
    }
  });
  Object.defineProperty(_exports, "_setRegisteredPollers", {
    enumerable: true,
    get: function () {
      return _pollTask._setRegisteredPollers;
    }
  });
  Object.defineProperty(_exports, "_setRegisteredTimers", {
    enumerable: true,
    get: function () {
      return _runTask._setRegisteredTimers;
    }
  });
  Object.defineProperty(_exports, "addEventListener", {
    enumerable: true,
    get: function () {
      return _domEventListeners.addEventListener;
    }
  });
  Object.defineProperty(_exports, "cancelDebounce", {
    enumerable: true,
    get: function () {
      return _debounceTask.cancelDebounce;
    }
  });
  Object.defineProperty(_exports, "cancelPoll", {
    enumerable: true,
    get: function () {
      return _pollTask.cancelPoll;
    }
  });
  Object.defineProperty(_exports, "cancelTask", {
    enumerable: true,
    get: function () {
      return _runTask.cancelTask;
    }
  });
  Object.defineProperty(_exports, "debounceTask", {
    enumerable: true,
    get: function () {
      return _debounceTask.debounceTask;
    }
  });
  Object.defineProperty(_exports, "pollTask", {
    enumerable: true,
    get: function () {
      return _pollTask.pollTask;
    }
  });
  Object.defineProperty(_exports, "pollTaskFor", {
    enumerable: true,
    get: function () {
      return _pollTask.pollTaskFor;
    }
  });
  Object.defineProperty(_exports, "registerDisposable", {
    enumerable: true,
    get: function () {
      return _disposable.registerDisposable;
    }
  });
  Object.defineProperty(_exports, "removeEventListener", {
    enumerable: true,
    get: function () {
      return _domEventListeners.removeEventListener;
    }
  });
  Object.defineProperty(_exports, "runDisposables", {
    enumerable: true,
    get: function () {
      return _disposable.runDisposables;
    }
  });
  Object.defineProperty(_exports, "runTask", {
    enumerable: true,
    get: function () {
      return _runTask.runTask;
    }
  });
  Object.defineProperty(_exports, "scheduleTask", {
    enumerable: true,
    get: function () {
      return _runTask.scheduleTask;
    }
  });
  Object.defineProperty(_exports, "setShouldPoll", {
    enumerable: true,
    get: function () {
      return _pollTask.setShouldPoll;
    }
  });
  Object.defineProperty(_exports, "throttleTask", {
    enumerable: true,
    get: function () {
      return _runTask.throttleTask;
    }
  });
});