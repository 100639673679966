define('ember-side-menu/components/side-menu', ['exports', 'ember-side-menu/utils/gestures'], function (exports, _gestures) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const styleProps = ['shadowStyle', 'positionStyle', 'transitionStyle', 'transformStyle'];

  exports.default = Ember.Component.extend({
    sideMenu: Ember.inject.service(),

    progress: Ember.computed.alias('sideMenu.progress'),
    isOpen: Ember.computed.alias('sideMenu.isOpen'),
    isClosed: Ember.computed.alias('sideMenu.isClosed'),
    isSlightlyOpen: Ember.computed.alias('sideMenu.isSlightlyOpen'),
    isTouching: false,
    disableMenu: false,

    attributeBindings: ['style'],
    classNames: ['side-menu'],
    classNameBindings: ['isInProgress:disable-scroll'],

    side: 'left',
    width: '70%',
    rootNodeSelector: 'body',

    initialTapAreaWidth: 30,
    slightlyOpenWidth: 20,
    slightlyOpenAfter: 300,

    shadowStyle: Ember.computed('progress', function () {
      const progress = Ember.get(this, 'progress');
      return progress === 0 ? 'box-shadow: none;' : '';
    }),

    positionStyle: Ember.computed('width', 'side', function () {
      const width = Ember.get(this, 'width');
      const side = Ember.get(this, 'side');

      if (side === 'left') {
        return `width: ${width}; right: initial; left: -${width};`;
      }

      return `width: ${width}; left: initial; right: -${width};`;
    }),

    transitionStyle: Ember.computed('progress', function () {
      const progress = Ember.get(this, 'progress');
      return progress === 0 || progress === 100 ? 'transition: transform 0.2s ease-out;' : 'transition: none;';
    }),

    transformStyle: Ember.computed('progress', 'side', 'isSlightlyOpen', function () {
      const side = Ember.get(this, 'side');
      const isSlightlyOpen = Ember.get(this, 'isSlightlyOpen');
      const slightlyOpenWidth = Ember.get(this, 'slightlyOpenWidth');
      const direction = side === 'right' ? '-' : '';
      const unit = isSlightlyOpen ? 'px' : '%';
      const progress = isSlightlyOpen ? slightlyOpenWidth : Ember.get(this, 'progress');

      return `transform: translateX(${direction}${progress}${unit});`;
    }),

    style: Ember.computed(...styleProps, function () {
      const combinedStyle = styleProps.reduce((acc, style) => acc + Ember.get(this, style), '');

      return Ember.String.htmlSafe(combinedStyle);
    }),

    _setScrollDisable() {
      const isClosed = Ember.get(this, 'isClosed');
      const wasClosed = Ember.get(this, 'wasClosed');

      if (isClosed === wasClosed) return;

      const rootNode = document.querySelector(Ember.get(this, 'rootNodeSelector'));

      if (isClosed) {
        rootNode.classList.remove('disable-scroll');
      } else {
        rootNode.classList.add('disable-scroll');
      }

      Ember.set(this, 'wasClosed', isClosed);
    },

    didInsertElement() {
      this._super(...arguments);
      this._setupEventListeners();
      this._setupObservers();
    },

    willDestroyElement() {
      this._super(...arguments);
      this._removeEventListeners();
      this._removeObservers();
    },

    _setupEventListeners() {
      const rootNode = document.querySelector(Ember.get(this, 'rootNodeSelector'));
      const onRootNodeTouch = Ember.run.bind(this, this._onRootNodeTouch);

      rootNode.addEventListener('touchstart', onRootNodeTouch);

      Ember.run.schedule('afterRender', () => {
        Ember.set(this, 'onTouchStart', onRootNodeTouch);
      });
      const onMenuScroll = () => {
        if (!Ember.get(this, 'disableMenu') && !Ember.get(this, 'isInProgress')) {
          const enableMenuOnce = e => {
            Ember.set(this, 'disableMenu', false);
            e.target.removeEventListener(e.type, enableMenuOnce);
          };
          Ember.set(this, 'disableMenu', true);
          Ember.get(this, 'element').addEventListener('touchend', enableMenuOnce);
        }
      };
      Ember.get(this, 'element').addEventListener('scroll', onMenuScroll);
    },

    _setupObservers() {
      this._setScrollDisable();
      this.addObserver('isClosed', this, '_setScrollDisable');
    },

    _removeObservers() {
      this.removeObserver('isClosed', this, '_setScrollDisable');
    },

    _removeEventListeners() {
      const onTouchStart = Ember.get(this, 'onTouchStart');
      const rootNode = document.querySelector(Ember.get(this, 'rootNodeSelector'));

      rootNode.removeEventListener('touchstart', onTouchStart);
    },

    _onRootNodeTouch(evt) {
      let runOpenMenuSlightly;
      const rootNode = document.querySelector(Ember.get(this, 'rootNodeSelector'));
      const onTouchMove = event => {
        if (runOpenMenuSlightly) {
          Ember.run.cancel(runOpenMenuSlightly);
        }

        if (Ember.get(this, 'disableMenu')) return;

        if (!(this._isTouchWithin(event, Ember.get(this, 'slightlyOpenWidth')) && Ember.get(this, 'isClosed'))) {
          if (Ember.get(this, 'isSlightlyOpen')) {
            Ember.set(this, 'isSlightlyOpen', false);
          }

          if (!Ember.get(this, 'isInProgress') && this._isInitialGesture(event)) {
            Ember.set(this, 'isInProgress', true);
          }

          if (Ember.get(this, 'isInProgress')) {
            this._updateProgress(event.touches[0].pageX);
          }
        }
      };
      const onTouchEnd = event => {
        rootNode.removeEventListener('touchmove', onTouchMove);
        rootNode.removeEventListener('touchend', onTouchEnd);
        Ember.set(this, 'isTouching', false);
        Ember.set(this, 'isInProgress', false);

        if (runOpenMenuSlightly) {
          Ember.run.cancel(runOpenMenuSlightly);
        }

        this._completeMenuTransition(event);
      };

      Ember.set(this, 'isTouching', true);

      if (this._validToStartTracking(evt)) {
        Ember.set(this, 'touchStartEvent', evt);
        this._setTouchOffset(evt);

        if (this._isTouchWithin(evt, Ember.get(this, 'initialTapAreaWidth'))) {
          runOpenMenuSlightly = Ember.run.later(() => {
            if (Ember.get(this, 'isClosed') && Ember.get(this, 'isTouching')) {
              Ember.set(this, 'isSlightlyOpen', true);
            }
          }, Ember.get(this, 'slightlyOpenAfter'));
        }

        rootNode.addEventListener('touchmove', onTouchMove);
        rootNode.addEventListener('touchend', onTouchEnd);
      }
    },

    _setTouchOffset(event) {
      const isOpen = Ember.get(this, 'isOpen');
      const pageX = event.touches[0].pageX;
      const side = Ember.get(this, 'side');

      if (isOpen) {
        if (side === 'left') {
          Ember.set(this, 'touchOffset', Math.max(0, this.element.offsetWidth - pageX));
        } else {
          Ember.set(this, 'touchOffset', Math.max(0, this.element.offsetWidth - (window.innerWidth - pageX)));
        }
      } else {
        Ember.set(this, 'touchOffset', 0);
      }
    },

    _updateProgress(touchPageX) {
      const elementWidth = this.element.offsetWidth;
      const touchOffset = Ember.get(this, 'touchOffset');
      const side = Ember.get(this, 'side');
      const relativeX = side === 'left' ? touchPageX : window.innerWidth - touchPageX;
      const progress = Math.min(100 * ((relativeX + touchOffset) / elementWidth), 100);

      Ember.set(this, 'progress', progress);
    },

    _completeMenuTransition(event) {
      const progress = Ember.get(this, 'progress');
      const touchStartEvent = Ember.get(this, 'touchStartEvent');
      const side = Ember.get(this, 'side');
      const gesture = (0, _gestures.createGesture)(touchStartEvent, event);
      const minClosingVelocity = 0.3;
      const autoCompleteThreshold = 50;
      const isSwipingLeft = gesture.velocityX > minClosingVelocity;
      const isSwipingRight = gesture.velocityX < -minClosingVelocity;

      const isClosingMovement = side === 'left' && isSwipingLeft || side === 'right' && isSwipingRight;
      const isOpeningMovement = side === 'left' && isSwipingRight || side === 'right' && isSwipingLeft;

      if (isClosingMovement || progress < autoCompleteThreshold) {
        Ember.get(this, 'sideMenu').close();
      } else if (isOpeningMovement || progress >= autoCompleteThreshold) {
        Ember.get(this, 'sideMenu').open();
      }
    },

    _validToStartTracking(event) {
      return Ember.get(this, 'isOpen') || this._isTouchWithin(event, Ember.get(this, 'initialTapAreaWidth'));
    },

    _isInitialGesture(event) {
      const touchStartEvent = Ember.get(this, 'touchStartEvent');
      const gesture = (0, _gestures.createGesture)(touchStartEvent, event);
      const minTime = 10; // 10 ms minimum time of gesture
      const isMoreSwiping = Math.abs(gesture.velocityX) > Math.abs(gesture.velocityY);
      return gesture.time > minTime && isMoreSwiping;
    },

    _isTouchWithin(event, areaWidth) {
      const side = Ember.get(this, 'side');
      const pageX = event.touches[0].pageX;

      return side === 'left' && pageX < areaWidth || side === 'right' && pageX > window.innerWidth - areaWidth;
    }
  });
});