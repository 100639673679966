define('ember-burger-menu/animations/index', ['exports', 'ember-require-module'], function (exports, _emberRequireModule) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = getAnimation;


  const ANIMATION_PATH = 'ember-burger-menu/animations';
  const ANIMATION_ITEM_PATH = `${ANIMATION_PATH}/menu-item`;

  function getAnimation(animation, itemAnimation) {
    let AnimationClass;

    if (Ember.typeOf(animation) === 'class' && animation.__isAnimation__) {
      AnimationClass = animation;
    } else {
      AnimationClass = (0, _emberRequireModule.default)(`${ANIMATION_PATH}/${animation}`);
      (true && !(AnimationClass) && Ember.assert(`The animation '${animation}' could not be found.`, AnimationClass));
    }

    if (!Ember.isEmpty(itemAnimation)) {
      let MenuItemMixin = (0, _emberRequireModule.default)(`${ANIMATION_ITEM_PATH}/${itemAnimation}`);

      (true && !(MenuItemMixin) && Ember.assert(`The item animation '${itemAnimation}' could not be found.`, MenuItemMixin));


      return AnimationClass.extend(MenuItemMixin);
    }

    return AnimationClass;
  }
});