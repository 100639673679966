define('ember-inputmask/components/phone-number-input', ['exports', 'ember-inputmask/components/input-mask'], function (exports, _inputMask) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _inputMask.default.extend({
    mask: '(299) 999-9999',

    oldComponent: '{{phone-number-input}}',
    newComponent: '{{one-way-phone-mask}}',

    updateMask: function () {
      if (this.get('extensions')) {
        this.set('mask', '(299) 999-9999[ x 9{1,4}]');
      }

      this._super();
    },

    _maskShouldChange: Ember.observer('mask', 'extensions', function () {
      Ember.run.once(this, 'updateMask');
    })
  });
});