define('ember-side-menu/components/side-menu-toggle', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    sideMenu: Ember.inject.service(),

    classNameBindings: ['side'],
    classNames: ['side-menu-toggle'],

    side: 'left',

    click() {
      Ember.get(this, 'sideMenu').toggle();
    }
  });
});