define('ember-cp-validations/utils/utils', ['exports', 'ember-require-module'], function (exports, _emberRequireModule) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.unwrapString = unwrapString;
  exports.unwrapProxy = unwrapProxy;
  exports.isProxy = isProxy;
  exports.isPromise = isPromise;
  exports.isDsModel = isDsModel;
  exports.isDSManyArray = isDSManyArray;
  exports.isEmberObject = isEmberObject;
  exports.isObject = isObject;
  exports.isDescriptor = isDescriptor;
  exports.isValidatable = isValidatable;
  exports.getValidatableValue = getValidatableValue;
  exports.mergeOptions = mergeOptions;


  const DS = (0, _emberRequireModule.default)('ember-data');

  const {
    get,
    typeOf,
    isArray,
    canInvoke,
    A: emberArray
  } = Ember;

  const { isHTMLSafe } = Ember.String;

  const assign = Ember.assign || Ember.merge;

  function unwrapString(s) {
    if (isHTMLSafe(s)) {
      return s.toString();
    }

    return s;
  }

  function unwrapProxy(o) {
    return isProxy(o) ? unwrapProxy(get(o, 'content')) : o;
  }

  function isProxy(o) {
    return !!(o && (o instanceof Ember.ObjectProxy || o instanceof Ember.ArrayProxy));
  }

  function isPromise(p) {
    return !!(p && canInvoke(p, 'then'));
  }

  function isDsModel(o) {
    return !!(DS && o && o instanceof DS.Model);
  }

  function isDSManyArray(o) {
    return !!(DS && o && isArray(o) && (o instanceof DS.PromiseManyArray || o instanceof DS.ManyArray));
  }

  function isEmberObject(o) {
    return !!(o && o instanceof Ember.Object);
  }

  function isObject(o) {
    return typeOf(o) === 'object' || typeOf(o) === 'instance';
  }

  function isDescriptor(o) {
    return o && typeof o === 'object' && o.isDescriptor;
  }

  function isValidatable(value) {
    let v = unwrapProxy(value);
    return isDsModel(v) ? !get(v, 'isDeleted') : true;
  }

  function getValidatableValue(value) {
    if (!value) {
      return value;
    }

    if (isDSManyArray(value)) {
      return emberArray(value.filter(v => isValidatable(v)));
    }

    return isValidatable(value) ? value : undefined;
  }

  function mergeOptions(...options) {
    let o = {};

    for (let i = options.length - 1; i >= 0; i--) {
      let _o = options[i];
      assign(o, isObject(_o) ? _o : {});
    }

    return o;
  }
});