define('ember-i18n-cp-validations/validators/messages', ['exports', 'ember-cp-validations/validators/messages'], function (exports, _messages) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const {
    Handlebars
  } = Ember;

  function isSafeString(input) {
    return typeof Ember.String.isHTMLSafe === 'function' ? Ember.String.isHTMLSafe(input) : input instanceof Handlebars.SafeString;
  }

  function unwrap(input) {
    if (isSafeString(input)) {
      return input.toString();
    }

    return input;
  }

  function emitWarning(msg, meta, ENV) {
    if (!Ember.get(ENV, 'i18n.suppressWarnings')) {
      (true && Ember.warn(msg, false, meta));
    }
  }

  exports.default = _messages.default.extend({
    i18n: Ember.inject.service(),
    _regex: /\{\{(\w+)\}\}|\{(\w+)\}/g,

    _prefix: Ember.computed('prefix', function () {
      const prefix = Ember.get(this, 'prefix');

      if (typeof prefix === 'string') {
        if (prefix.length) {
          return prefix + '.';
        }

        return prefix;
      }

      return 'errors.';
    }),

    init() {
      this._super(...arguments);

      this._config = Ember.getOwner(this).resolveRegistration('config:environment') || {};
    },

    getDescriptionFor(attribute, context = {}) {
      const prefix = Ember.get(this, '_prefix');
      let key = `${prefix}description`;
      let setDescriptionKey;

      if (!Ember.isEmpty(context.descriptionKey)) {
        key = context.descriptionKey;
        setDescriptionKey = true;
      } else if (!Ember.isEmpty(context.description)) {
        return context.description;
      }

      const i18n = Ember.get(this, 'i18n');

      if (i18n && i18n.exists(key)) {
        return unwrap(i18n.t(key, context));
      }

      if (setDescriptionKey) {
        emitWarning(`Custom descriptionKey ${key} provided but does not exist in i18n translations.`, {
          id: 'ember-i18n-cp-validations-missing-description-key'
        }, this._config);
      }

      return this._super(...arguments);
    },

    getMessageFor(type, context = {}) {
      const i18n = Ember.get(this, 'i18n');
      const prefix = Ember.get(this, '_prefix');
      const key = Ember.isPresent(context.messageKey) ? context.messageKey : `${prefix}${type}`;

      if (i18n && i18n.exists(key)) {
        return unwrap(i18n.t(key, context));
      }

      emitWarning(`[ember-i18n-cp-validations] Missing translation for validation key: ${key}\nhttp://offirgolan.github.io/ember-cp-validations/docs/messages/index.html`, {
        id: 'ember-i18n-cp-validations-missing-translation'
      }, this._config);

      return this._super(...arguments);
    },

    formatMessage(message, context) {
      let m = message;

      if (Ember.isNone(m) || typeof m !== 'string') {
        m = Ember.get(this, 'invalid');
      }

      return m.replace(Ember.get(this, '_regex'), (s, p1, p2) => {
        return Ember.get(context, p1 || p2);
      });
    }
  });
});