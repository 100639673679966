define('ember-side-menu/services/side-menu', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    // progress 0-100 %
    progress: 0,
    isOpen: Ember.computed.equal('progress', 100),
    isClosed: Ember.computed.equal('progress', 0),
    isSlightlyOpen: false,

    close() {
      Ember.set(this, 'progress', 0);
      Ember.set(this, 'isSlightlyOpen', false);
    },

    open() {
      Ember.set(this, 'progress', 100);
      Ember.set(this, 'isSlightlyOpen', false);
    },

    toggle() {
      if (Ember.get(this, 'isOpen')) {
        this.close();
      } else {
        this.open();
      }
    }
  });
});