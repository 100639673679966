define("ember-collapsible-panel/components/cp-panel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "THJW3kYp",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[27,\"hash\",null,[[\"toggle\",\"body\",\"name\",\"isOpen\"],[[27,\"component\",[\"cp-panel-toggle\"],[[\"on-click\",\"isOpen\"],[[27,\"action\",[[22,0,[]],\"toggleIsOpen\"],null],[23,[\"isOpen\"]]]]],[27,\"component\",[\"cp-panel-body\"],[[\"shouldAnimate\",\"isOpen\"],[[23,[\"shouldAnimate\"]],[23,[\"isOpen\"]]]]],[23,[\"name\"]],[23,[\"isOpen\"]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-collapsible-panel/components/cp-panel/template.hbs"
    }
  });

  _exports.default = _default;
});