define('ember-collapsible-panel/services/dependency-checker', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    hasLiquidFire: Ember.computed('', function () {
      let config = Ember.getOwner(this).resolveRegistration('config:environment');

      return config['ember-collapsible-panel'].hasLiquidFire;
    })

  });
});