define("ember-burger-menu/templates/components/bm-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8rmLnCiJ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"ember-wormhole\",null,[[\"to\",\"renderInPlace\"],[[27,\"concat\",[[23,[\"containerId\"]],\"-outlet-menu\"],null],[23,[\"renderInPlace\"]]]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"bm-menu\"],[12,\"style\",[21,\"style\"]],[9],[0,\"\\n    \"],[14,1,[[27,\"hash\",null,[[\"item\"],[[27,\"component\",[\"bm-menu-item\"],[[\"tagName\",\"menuItems\",\"dismissOnClick\",\"state\"],[[23,[\"itemTagName\"]],[23,[\"menuItems\"]],[23,[\"dismissOnItemClick\"]],[23,[\"state\"]]]]]]]]]],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-burger-menu/templates/components/bm-menu.hbs"
    }
  });

  _exports.default = _default;
});